<template>
  <div class="resume">
    <div class="container-flex">
      <div class="row">
        <div class="col">
          <iframe class="resumeIframe" src="/resume.png"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .resumeIframe {
    width: 80%;
    height: 100vh;
    
  }
</style>