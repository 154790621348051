<template>
  <div class="pianoView">
    <div class="container-flex">
      <div class="row">
        <div class="col">
          <a @click="getSong('prelude_in_c')">Prelude in C Major</a>
          <br/>
          <a @click="getSong('jesu')">Jesu, Joy of Man's Desiring</a>
          <br/>
          <!--a @click="getSong('prelude_in_c')">Sheep May Safely Graze</a>
          <br/>
          <a >Inventions No 4 in D Minor</a-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSongUrl } from '@/js/aws_api.js'


export default {
  name: 'Piano',
  components: {
  },
  computed: {
    isDataLoaded() {
      return false
    }
  },
  data(){
    return {
    }
  },
  methods: {
    async getSong(songName) {

      console.log('start')

      const url = await getSongUrl(songName)
      console.log(url)

      window.location.href = url;
    },
  }
}
</script>

<style scoped>
.pianoView {
    background-color: black;
    color: white;
}
</style>